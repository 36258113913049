<template>
  <nav class="nav" :class="{ 'nav--open': isMenuOpen }">
    <div class="nav__header">
      <a href="#" @click="this.$router.push({name: 'Home'})"
        ><img class="nav__logo" :src="logoSrc" alt="logo"
      /></a>
      <button class="nav__toggle" @click="toggleMenu">
        <span class="nav__menu" v-if="!isMenuOpen">☰</span>
        <img v-else src="@/assets/close.svg" alt="Close" />
      </button>
    </div>
    <ul class="nav__list" v-if="this.$route.name !== 'WebPlatformTutorialPage'">
      <li class="nav__item">
        <a
          href="#"
          @click.prevent="scrollToSection('demand')"
          class="nav__link"
          :class="{ 'nav__link--active': currentSection === 'demand' }"
          >Retail</a
        >
      </li>
      <li class="nav__item">
        <a
          href="#"
          @click.prevent="scrollToSection('stock')"
          class="nav__link"
          :class="{ 'nav__link--active': currentSection === 'stock' }"
          >Stock Market</a
        >
      </li>
      <!-- <li class="nav__item">
        <a
          href="#"
          @click.prevent="scrollToSection('indicators')"
          class="nav__link"
          :class="{ 'nav__link--active': currentSection === 'indicators' }"
          >World indicators</a
        >
      </li> -->
      <!-- <li class="nav__item">
        <router-link :to="'/tutorial'" :class="routeClass" @click="closeModals"
          >APIs</router-link
        >
      </li> -->
      <!-- <li class="nav__item">
        <router-link :to="'/about'" class="nav__link" @click="closeModals"
          >About Us</router-link
        >
      </li> -->
    </ul>
    <nav class="dot-menu">
      <ul>
        <li
          v-for="section in sections"
          :key="section.id"
          :class="{ active: currentSection === section.id }"
          @click="scrollToSection(section.id)"
        >
          <span class="dot"></span>
        </li>
      </ul>
    </nav>
    <div class="nav__buttons" v-if="this.$route.name !== 'WebPlatformTutorialPage'">
      <!-- <button :class="buttonClass" @click="$emit('open-demo-modal')">
        Request demo
      </button> -->
      <button :class="buttonClass" @click="$emit('open-login-modal')">
        Enterprise login
      </button>
      <button
        v-if="this.token"
        :class="buttonClass"
        @click.stop="goToBackoffice"
      >
        Platform
      </button>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import logoLight from "@/assets/nav/logo-light.svg";
import logo from "@/assets/logo.svg";
export default {
  data() {
    return {
      isMenuOpen: false,
      windowWidth: 0,
      token: "",
      isMobile: window.innerWidth <= 768,
      sections: [
        { id: "home", name: "home" },
        { id: "demand", name: "demand" },
        { id: "stock", name: "stock" }
       /* { id: "indicators", name: "indicators" }*/
      ],
      currentSection: "home", // Identifica a seção ativa
    };
  },
  mounted() {
    setTimeout(() => {
      const container = document.getElementById("sections-container");

      if (container) {
        container.addEventListener("scroll", this.handleScroll); // Adicionar o evento de scroll
        this.initializeSections(); // Inicializar as seções
      }
    }, 0);

    this.getToken();
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  },

  computed: {
    logoSrc() {
      if (this.$route.name === "Home") {
        return logoLight;
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword" ||
        this.$route.name === "APIsPage" ||
        this.$route.name === "WebPlatformTutorialPage"
      ) {
        return this.isMobile && this.isMenuOpen ? logoLight : logo;
      } else {
        return "";
      }
    },
    routeClass() {
      if (this.$route.name === "Home") {
        return "nav__link";
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword" ||
        this.$route.name === "APIsPage" ||
        this.$route.name === "WebPlatformTutorialPage"
      ) {
        return this.isMobile
          ? "nav__research-link--mobile"
          : "nav__research-link";
      } else {
        return "";
      }
    },
    buttonClass() {
      if (this.$route.name === "Home") {
        return "nav__button";
      } else if (
        this.$route.name === "ResearchPage" ||
        this.$route.name === "SetPassword" ||
        this.$route.name === "APIsPage" 
      ) {
        return this.isMobile
          ? "nav__research-button nav__research-button--mobile"
          : "nav__research-button";
      } else {
        return "";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  methods: {
    isActive(sectionId) {
      return this.currentSection === sectionId;
    },
    handleScroll() {
      const sections = document.querySelectorAll("section");
      let sectionInView = null;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();

        // Verifica se a seção está visível no meio da tela
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          sectionInView = section.id; // Armazena a seção visível
        }
      });

      // Atualiza a seção ativa apenas se for diferente da atual
      if (sectionInView && this.currentSection !== sectionInView) {
        this.currentSection = sectionInView;
        console.log(this.currentSection)
      }
    },
    initializeSections() {
      setTimeout(() => {
        this.sections = this.sections.map((section) => {
          const element = document.getElementById(section.id);
          if (element) {
            return {
              ...section,
              element, // Armazena a referência DOM
            };
          } else {
            return section;
          }
        });
      }, 500); // Aguarda 100ms para garantir que o DOM está pronto
    },
    // Detecta a seção visível
    monitorVisibleSection() {
      console.log("monitor");
      if (!this.sections || this.sections.length === 0) return;

      const scrollPosition = window.scrollY + window.innerHeight / 2; // Ponto médio da viewport
      let nearestSection = null;
      let smallestDistance = Infinity;

      for (const section of this.sections) {
        if (section.element) {
          const rect = section.element.getBoundingClientRect();
          const sectionPosition = window.scrollY + rect.top; // Posição real da seção

          const distance = Math.abs(sectionPosition - scrollPosition);

          if (distance < smallestDistance) {
            smallestDistance = distance;
            nearestSection = section;
          }
        }
      }

      if (nearestSection && this.currentSection !== nearestSection.id) {
        this.currentSection = nearestSection.id;
      }
    },

    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.currentSection = sectionId; // Atualiza a seção ativa
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    validateToken(token) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        url: "https://api-brands.assetfloow.com/api/v1/auth/login/",
      })
        .then((response) => {
          if (response.status != 200) {
            this.token = "";
            window.$cookies.remove("auth-token", null, ".assetfloow.com");
          } else {
            this.token = response.data.auth_token;
            return response;
          }
        })
        .catch(() => {
          window.$cookies.remove("auth-token");
          this.token = "";
        });
    },
    goToBackoffice() {
      window.location.assign("https://ai.assetfloow.com");
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeModals() {
      this.$emit("close-modals");
      this.isMenuOpen = false;
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 900) {
        this.isMenuOpen = false;
      }
    },
    getToken() {
      this.token = window.$cookies.get("auth-token");
      if (this.token) {
        this.validateToken(this.token);
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateWidth);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

.nav {
  color: $white-color;
  width: 100%;
  padding: $nav-padding;
  position: fixed;
  top: 0;
  z-index: 8;

  @include responsive(1025px) {
    @include flex-center;
  }

  &--open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 38;
    width: 100vw;
    height: 100vh;
    background: url("@/assets/introduction/background-hero.png");
    background-size: cover;
    align-items: flex-start;

    .nav__header {
      position: relative;
      top: 7px;
    }

    .nav__list {
      @include flex-direction(column);

      display: block;
    }

    .nav__buttons {
      @include flex-center;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 80px;
      padding: 0 5%;
    }
  }

  &__header {
    @include flex-center;
    width: 100%;

    @include responsive(1025px) {
      justify-content: flex-start;
      width: auto;
    }
  }

  &__logo {
    width: $logo-width-small;

    @include responsive(1025px) {
      width: $logo-width-large;
    }
  }

  &__toggle {
    border: none;
    background: none;
    cursor: pointer;

    @include responsive(1025px) {
      display: none;
    }
  }

  &__menu {
    font-size: 30px;
    color: white;
  }

  &__list {
    display: none;
    flex-direction: column;
    margin-top: 60px;
    list-style: none;

    @include responsive(1025px) {
      @include flex-direction;

      margin: 0;
      padding: 0;
    }
  }

  &__item {
    margin-right: 1.5rem;

    @include responsive(1025px) {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    font-size: 32px;
    line-height: 62px;
    color: white;
    text-decoration: none;

    &--active {
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
    }

    @include responsive(1025px) {
      font-size: initial;
      line-height: initial;
    }
  }

  &__research-link {
    font-size: 32px;
    line-height: 62px;
    color: #00204d;
    text-decoration: none;

    &--mobile {
      font-size: 32px;
      line-height: 62px;
      color: white;
      text-decoration: none;
    }

    &:hover {
      border-bottom: 2px solid;
    }

    @include responsive(1025px) {
      font-size: initial;
      line-height: initial;
    }
  }

  &__buttons {
    display: none;

    @include responsive(1025px) {
      @include flex-center;
      position: relative;
      width: auto;
    }
  }

  &__research-button {
    @include button;

    box-shadow: none;
    background: transparent;
    color: #00204d;
    border-color: #00204d;

    &--mobile {
      color: white;
      border-color: white;
    }

    &:hover {
      border-color: #2768f6;
    }

    &:nth-child(2) {
      @include button;

      background: $button-background-2;
      border: none;
      box-shadow: none;
      color: $white-color;

      @include responsive(1025px) {
        width: auto;
        margin-left: 0.5rem;
      }
    }
  }

  &__button {
    @include button;

    box-shadow: none;
    background: transparent;
    color: white;
    border-color: white;

    &:nth-child(2) {
      @include button;

      background: $button-background-2;
      border: none;
      box-shadow: none;
      color: $white-color;

      @include responsive(1025px) {
        width: auto;
        margin-left: 0.5rem;
      }
    }
  }
}

.router-link-active {
  border-bottom: 2px solid;
}

.dot-menu {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1000;
}

.dot-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dot-menu li {
  margin: 10px 0;
  cursor: pointer;
}

.dot-menu .dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid white;
  transition: background-color 0.3s;
}

.dot-menu li.active .dot {
  background-color: white;
  box-shadow: 0 0 5px #ffffff;
}

@media (max-width: 768px) {
  .dot-menu {
    right: 10px;
  }
  .dot-menu .dot {
    width: 10px;
    height: 10px;
  }
}
</style>
