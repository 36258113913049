<template>
  <div class="setpw__container">
    <h5 class="setpw__subheadline">Set Password</h5>
    <form class="set__form" @submit.prevent="submitForm">
      <div class="set__input-wrapper">
        <input
          class="set__input"
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          :disabled="disabled"
          placeholder="Password"
        />
        <span class="password-toggle" @click="togglePasswordVisibility">
          <i :class="showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"></i>
        </span>
      </div>
      <div class="set__input-wrapper">
        <input
          class="set__input"
          :type="showConfirmPassword ? 'text' : 'password'"
          v-model="confirmPassword"
          :disabled="disabled"
          placeholder="Confirm Password"
        />
        <span class="password-toggle" @click="toggleConfirmPasswordVisibility">
          <i
            :class="showConfirmPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"
          ></i>
        </span>
      </div>
      <button class="set__button" id="submit_set_btn" type="submit">
        Set Password
      </button>
    </form>
    <div id="snackbar"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.validateToken(this.$route.params.token);
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      isBrand: false,
      isRetailer: false,
      tokenBrand: null,
      tokenCompany: null,
      disabled: false,

      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    validateToken(token) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        url: "https://api-brands.assetfloow.com/api/v1/auth/login/",
      })
        .then((response) => {
          if (response.status != 200) {
            this.showSnackbarError(
              "The token is not valid. Try again or contact administrator."
            );
            this.disabled = true;
          } else {
            this.tokenBrand = response.data.auth_token;
            return response;
          }
        })
        .then(() => {
          if (this.tokenBrand) this.isBrand = true;
        })
        .catch(() => {
          this.disabled = true;
          this.showSnackbarError(
            "The token is not valid. Try again  or contact administrator."
          );
        });
    },
    clearFields() {
      this.password = "";
      this.confirmPassword = "";
    },
    submitForm() {
      if (!this.password || !this.confirmPassword) {
        this.requiredFields();
        return;
      }

      if (this.password != this.confirmPassword) {
        this.showSnackbarError("The passwords don't match.");
        return;
      }

      var url = "";
      var data = null;

      url =
        "https://api-brands.assetfloow.com/api/v1/auth/password/confirm/:id/:token/";
      url = url.replace(":id", this.$route.params.id);
      url = url.replace(":token", this.$route.params.token);
      data = {
        new_password: this.password,
        confirm_password: this.confirmPassword,
      };

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: url,
        data: data,
      })
        .then(function (response) {
          if (response.status != 200) {
            this.showSnackbarError(
              "There was an error setting. Try again later."
            );
            return;
          } else {
            return response;
          }
        })
        .then(() => {
          this.clearFields();
          this.showSnackbarSuccess("Password set successfully.");
        })
        .catch(() => {
          this.showSnackbarError(
            "There was an error setting the password. Try again later."
          );
        });
    },
    showSnackbarSuccess(text) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✔ " + text;
      snackbar.style.background = "#7da53a";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    showSnackbarError(error) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✗ " + error;
      snackbar.style.background = "#962929";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    requiredFields() {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "！Please insert all the fields.";
      snackbar.style.background = "#e5ab46";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
@import "../styles/styles.scss";

#snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 320px;
  /* Set a default minimum width */

  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 8px;
  /* Rounded borders */
  padding: 0.5rem 1.5rem;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  right: 40%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.setpw {
  &__container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    margin-top: 8rem;
  }

  &__headline {
    font-size: 50px;
    line-height: 60px;
    padding-top: 40px;
    margin-bottom: 20px;
  }

  &__update {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 80px;
  }

  &__subheadline {
    font-size: 20px;
    line-height: 24px;
    margin: 2rem 0;
    position: relative;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 17px;
  }

  &__link {
    font-family: "aeonik";
    text-decoration: none;
    color: #00204d;
  }

  &__numbered {
    font-size: 14px;
    line-height: 17px;
    padding-left: 2rem;
  }

  &__lettered {
    list-style: none;
    padding-left: 1rem;
  }
}

.set {
  &__form {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgb(246, 249, 252);
    background: linear-gradient(
      180deg,
      rgba(246, 249, 252, 1) 0%,
      rgba(251, 242, 232, 1) 100%
    );

    @include responsive($breakpoint) {
      right: 0;
      width: 480px;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }
  }

  &__content {
    margin: 10% auto;
    padding-top: 100px;
    width: 100%;

    @include responsive($breakpoint) {
      max-width: 400px;
    }
  }

  &__headline {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0.5rem;
  }

  &__text {
    color: #abb3bf;
    font-size: 18px;
    margin-bottom: 80px;
  }

  &__close-btn,
  .demo-login__close-btn {
    font-size: 50px;
    position: absolute;
    right: 1rem;
    top: 0.5rem;

    &:hover,
    &:focus {
      color: lighten(black, 45%);
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__input-wrapper {
    margin-bottom: 2rem;
    position: relative;

    @include responsive($breakpoint) {
      width: 500px;
    }
  }

  &__input[type="password"] {
    @include forms(#f2efef);

    margin-bottom: unset;

    @include responsive($breakpoint) {
      width: 100%;
    }
  }

  &__input[type="text"] {
    @include forms(#f2efef);

    margin-bottom: unset;

    @include responsive($breakpoint) {
      width: 100%;
    }
  }

  &__button {
    @include button;
  }
}

.set__button {
  width: auto;
  height: auto;
  width: fit-content;
}

::placeholder {
  font-family: "aeoniklight";
  color: #abb3bf;
  font-size: 16px;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
