<template>
  <div class="demand-prediction">
    <div class="demand-prediction__wrapper"  :style="{ width: isMobile ? '80%' : isTablet ? '80%' : '40%' }">
      <h1 class="h1">
        Can <span style="font-weight: bold">Behavioural Forecast</span> be applied
        to demand prediction?
      </h1>
      <div style="margin-top: 4rem">
        <p class="p">
          “In my 20 years of experience with retail, I’ve never found anyhing
          similar to AssetFloow.”
        </p>
        <div class="client-wrapper">
          <p>Hirota Food, Brazilian supermarket</p>
        </div>
        <div style="margin-top: 2rem; text-align: start">
          <p class="p" style="line-height: 17px">
            “AssetFloow's ability to work with 80+ million rows of sales data
            and forecast store requirements with 93.74% accuracy is a testament
            to its power in providing datadriven decision-making and operational
            eficiency.”
          </p>
        </div>
        <div class="client-wrapper">
          <p>Wincanton, UK logistics provider</p>
        </div>
      </div>
      <button
        class="demand-prediction__button"
        :style="{ width: isMobile ? '' : '80%' }"
        style="pointer-events: auto;"
        @click="goToDeveloperPlatform"
      >
        Enter Prediction Platform
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      isTablet: false,
    };
  },
  methods: {
    goToDeveloperPlatform(){
      window.location.assign("https://developer.assetfloow.com");
    },
    checkDevice() {
      this.isMobile = window.innerWidth <= 768;
      this.isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
    },
  },
  created() {
    this.checkDevice();
    window.addEventListener("resize", this.checkDevice);
  },
  mounted() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>
<style scoped lang="scss">
@import "../styles/styles.scss";

.demand-prediction {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(295deg, rgba(0,117,117,1) 0%, rgba(194,128,152,1) 100%);
  height: 100vh;

  &__button {
    @include button(fit-content, fit-content);

    position: relative;
    background: $button-background-2;
    border: none;
    box-shadow: none;
    color: $white-color;
    z-index: 20;
    margin-top: 2rem;
  }

  &__wrapper {
    text-align: center;
    color: white;
  }
}

.h1 {
  font-size: 37px;
  font-weight: normal;
}

.client-wrapper {
  display: flex;
  justify-content: end;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
}

.p {
  font-size: 14px;
  font-style: italic;
}
</style>
