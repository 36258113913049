<template>
  <div class="tutorial container">
    <div class="tutorial__wrapper">
      <div>
        <!-- Forecast Univariate -->
        <div data-aos="fade-up">
          <h1>Start building accurate Behavioural Forecast in 2 steps</h1>
        </div>
        <div data-aos="fade-up">
          <div class="models__tabs-wrapper">
            <div class="models__tabs">
              <div
                class="models-tabs__header models-tabs-header"
                :class="{ 'models-tabs-header--open': isOpen(0) }"
                @click="toggleItem(0)"
              >
                <div class="models-tabs-header__content-wrapper">
                  <div class="models-tabs-header__content">
                    <p class="tutorial__step">01</p>
                    <span class="tutorial__step tutorial__step--divider"
                      >|</span
                    >
                    <p class="tutorial__step tutorial__step--title">
                      Register on the platform
                    </p>
                  </div>
                </div>
                <font-awesome-icon
                  style="color: white"
                  :icon="['fas', 'chevron-down']"
                  v-if="!isOpen(0)"
                />
                <font-awesome-icon
                  style="color: white"
                  :icon="['fas', 'chevron-up']"
                  v-if="isOpen(0)"
                />
              </div>
              <div v-if="isOpen(0)" class="models-tabs__body models-tabs-body">
                <ul class="ul">
                  <li class="li">Click "Enter Developer Platform" in <a href="https://www.assetfloow.com">www.assetfloow.com</a> </li>
                  <li class="li">If you are not registered, click "Get Started", fill your information and click "Register".</li>
                  <li class="li">Check your email for a link to activate your account.</li>
                  <li class="li">Return to "Enter Developer Platform" and login. </li>
                  <li class="li">Choose subscription free plan, and add your credit card information. We use Stripe to process payments, and we are required to always ask for credit card information, even if it free. You won't be charged on the free plan. </li>
                </ul>   
              </div>
            </div>
            <div class="models__tabs">
              <div
                class="models-tabs__header models-tabs-header"
                :class="{ 'models-tabs-header--open': isOpen(1) }"
                @click="toggleItem(1)"
              >
                <div class="models-tabs-header__content-wrapper">
                  <div class="models-tabs-header__content">
                    <p class="tutorial__step">02</p>
                    <span class="tutorial__step tutorial__step--divider"
                      >|</span
                    >
                    <p class="tutorial__step tutorial__step--title">
                     Generate Models
                    </p>
                  </div>
                </div>
                <font-awesome-icon
                  style="color: white"
                  :icon="['fas', 'chevron-down']"
                  v-if="!isOpen(1)"
                />
                <font-awesome-icon
                  style="color: white"
                  :icon="['fas', 'chevron-up']"
                  v-if="isOpen(1)"
                />
              </div>
              <div v-if="isOpen(1)" class="models-tabs__body models-tabs-body">
                <ul class="ul">
                  <li class="li">On the tab "Models" click "New Forecast Model" to setup the model.</li>
                  <li >Upload a .xslx or .csv file with the time series, that should only have 3 columns: one with dates, other with values and other with the IDs of those values. For example, if it is sales data, the columns should be one with the dates, other with he sales values and other with the names of the products. Write the names of those columns on the setup form and the expected output for the Forecast. </li>
                  <img
                  src="@/assets/web_platform_tutorial/models_form.png"
                  alt="models form"
                 class="models-tabs-body__img"
                />
                <li style="margin-top: 1rem;">Click "Generate DFN Forecast" and wait until the model is generated. The model will learn the behavioural patterns directly from the raw data to generate the forecast. Depending on the amount of data, it could take a few seconds to a few hours That's it! </li>
                 <img
                  src="@/assets/web_platform_tutorial/select_nodes.png"
                  alt="model forecast"
                  class="models-tabs-body__img"
                />
                </ul>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WebPlatformTutorialPage",
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      openItems: {},
      openIndex: null,
    };
  },
  methods: {
    /**
     * Check if the tab is opened.
     * @public
     */
    isOpen(index) {
      return this.openIndex === index;
    },
    /**
     * Open/Closes a tab.
     * @public
     */
    toggleItem(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";
.tutorial {
  margin-top: 8rem;

  &__step {
    font-size: 22px;
    color: white;
    font-weight: bold;

    &--divider {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &--title {
      color: white;
    }
  }

  &__wrapper {
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 2rem;
  }
}

.models-tabs-header {
  display: flex;
  align-items: center;
  width: 100%;
  background: #2768f6;
  padding: 24px;
  border-radius: 5px;
  height: 70px;

  &:hover {
    background: #1c52c7;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    margin-right: 1rem;

    &::before {
      font-size: 50px;
      color: white;
    }
  }

  &__content-wrapper {
    flex: 1;
    color: white;
  }

  &__content {
    display: flex;
    justify-content: start;
  }

  &__text {
    font-size: 13px;
    color: gray;
  }

  &__icon-info {
    margin-right: 2rem;
  }

  &--open {
    background: #1c52c7;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__icon-arrow {
    &--flip-vertical {
      transform: rotate(180deg);
    }
  }
}

.models-tabs-body {
  background: #f4f5f7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 24px;
  overflow: hidden;

  &__dot {
    display: flex;
    align-items: baseline;
  }
  &__dot-with-top-margin {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
  }

  &__img {
    border: 1px solid #1c52c7;
    margin-top: 20px;
  }
}

.models {
  &__text {
    margin: 1.5rem 0;

    &--error {
      color: #f44336;
    }
  }
  &__tabs {
    margin: 2rem 0;
  }

  &__texts-modal-inner {
    width: 60%;
    height: 95vh;
    overflow-y: auto;
  }

  &__button {
    background: #1745a3;

    &--right {
      margin-left: 20px;
    }
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #00204d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.ul {
  padding: 1rem;
}

.li {
  margin-bottom: 1rem;
}
</style>
