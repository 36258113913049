<template>
  <NavigationComponent
    @open-demo-modal="openDemoModal"
    @open-login-modal="openLoginModal"
  />
  <router-view />
  <DemoComponent :isOpen="isDemoModalOpen" @close="closeDemoModal" />
  <LoginComponent
    :isOpen="isLoginModalOpen"
    @close="closeLoginModal"
    @open-recovery-modal="openRecoveryModal"
  />
  <RecoveryPasswordComponent
    :isOpen="isRecoveryModalOpen"
    @close="closeRecoveryModal"
  />
 
  <!-- <section
    id="contacts"
    class="footer-component"
    :style="{ backgroundImage: `url(${backgroundImageFooter})` }"
  >
    <FooterComponent @open-demo-modal="openDemoModal" /> 
  </section> -->
</template>

<script>
import NavigationComponent from "./components/NavigationComponent.vue";
import DemoComponent from "./components/DemoComponent.vue";
import LoginComponent from "./components/LoginComponent.vue";
import RecoveryPasswordComponent from "./components/RecoveryPasswordComponent.vue";
//import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    NavigationComponent,
    DemoComponent,
    LoginComponent,
    RecoveryPasswordComponent,
    //FooterComponent,
  },
  data() {
    return {
      isDemoModalOpen: false,
      isLoginModalOpen: false,
      isRecoveryModalOpen: false,
      backgroundImageFooter: require("@/assets/section-footer.svg"),
      sections: [
        { id: "home", name: "Home" },
        { id: "retail", name: "Retail" },
        { id: "retail", name: "Retail" },
        { id: "testimonials", name: "testimonials" },
      ],
      currentSection: "home", // Identifica a seção ativa
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.currentSection = sectionId;
      }
    },
    openDemoModal() {
      this.isDemoModalOpen = true;
    },
    closeDemoModal() {
      this.isDemoModalOpen = false;
    },
    openLoginModal() {
      this.isLoginModalOpen = true;
    },
    closeLoginModal() {
      this.isLoginModalOpen = false;
    },
    openRecoveryModal() {
      this.isRecoveryModalOpen = true;
    },
    closeRecoveryModal() {
      this.isRecoveryModalOpen = false;
    },
  },
};


</script>
<style lang="scss" scoped>
.dot-menu {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1000;
}

.dot-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dot-menu li {
  margin: 10px 0;
  cursor: pointer;
}

.dot-menu .dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.dot-menu li.active .dot {
  background-color: #007bff;
}

.page-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  border-bottom: 1px solid #ddd;
}

.page-section:nth-child(odd) {
  background-color: #f9f9f9;
}
@media (max-width: 768px) {


  .dot-menu {
    right: 10px;
  }
  .dot-menu .dot {
    width: 10px;
    height: 10px;
  }
}
</style>