<template>
<div id="sections-container">
  <section id="home" ref="home" class="section">
    <IntroductionComponent />
  </section>
   <section id="demand" ref="demand" class="section">
    <ForecastDemandPrediction />
  </section>
  <section id="stock" ref="stock" class="section">
    <ForecastStockMarket/>
  </section>
  <!-- <section id="indicators" ref="indicators" class="section">
    <ForecastMarketIndicators /> 
  </section>-->
</div>
</template>

<script>
import IntroductionComponent from "@/components/IntroductionComponent.vue";
import ForecastDemandPrediction from "@/components/ForecastDemandPrediction.vue";
import ForecastStockMarket from "@/components/ForecastStockMarket.vue";
//import ForecastMarketIndicators from "@/components/ForecastMarketIndicators.vue";

export default {
  name: "App",
  components: {
    IntroductionComponent,
    ForecastDemandPrediction,
    ForecastStockMarket,
   // ForecastMarketIndicators,
  },
  data() {
    return {
      isDemoModalOpen: false,
      isMobile: window.innerWidth <= 768,
      backgroundImageTabs: require("@/assets/section-tabs.png"),
    };
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openDemoModal() {
      this.isDemoModalOpen = true;
    },
    closeDemoModal() {
      this.isDemoModalOpen = false;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
/* Adicionar scroll snapping no container das seções */
#sections-container {
  scroll-snap-type: y mandatory; /* Habilitar snap no eixo Y */
  overflow-y: scroll; /* Ativar scroll vertical */
  height: 100vh; /* Garante que cada seção ocupe a tela inteira */
  scroll-behavior: smooth; /* Smooth scrolling */
}

/* Configurar alinhamento das seções */
.section {
  position: relative;
  scroll-snap-align: start; /* Snap no topo de cada seção */
  height: 100vh; /* Cada seção ocupa 100% da altura da tela */
}
</style>